import Cookies from 'js-cookie';

const SiteAlert = {
  init: () => {
    const alertBanner = document.getElementById('site-alert');
    const search = document.getElementById('search-modal');
    const bannerCookie = Cookies.get('AlertDismissed');

    if (bannerCookie && alertBanner) {
      alertBanner.classList.add('hidden');
      if (!bannerCookie) {
        alertBanner.classList.remove('hidden');
      }
    }

    if (alertBanner && !bannerCookie) {
      const nav = document.querySelector('nav:not(.mobile-nav)');
      const mobileNav = document.querySelector('nav.mobile-nav');
      const layout = document.getElementById('layout');

      let bannerOffset = `${alertBanner.clientHeight}px`;
      let layoutOffset = `${(nav.clientHeight + alertBanner.clientHeight).toString()}px`;

      layout.style.marginTop = layoutOffset;
      search.style.marginTop = layoutOffset;
      nav.style.top = bannerOffset;
      mobileNav.style.top = bannerOffset;

      window.addEventListener('resize', () => {
        bannerOffset = `${alertBanner.clientHeight}px`;
        layoutOffset = `${(nav.clientHeight + alertBanner.clientHeight).toString()}px`;
        layout.style.marginTop = layoutOffset;
        search.style.marginTop = layoutOffset;
        nav.style.top = bannerOffset;
        mobileNav.style.top = bannerOffset;
      });

      if (document.querySelector('.dismiss-alert')) {
        const closeBtn = document.querySelector('.dismiss-alert');
        const popout = document.getElementById('popout-modal');

        closeBtn.addEventListener('click', () => {
          if (popout) popout.style.top = '40%';
          Cookies.set('AlertDismissed', 'true');
          Cookies.set('ProgrammeAlertDismissed', 'true');
          alertBanner.classList.add('hidden');
          layout.removeAttribute('style');
          search.removeAttribute('style');
          nav.removeAttribute('style');
          mobileNav.removeAttribute('style');
        });
      }
    }
  },
};

export default SiteAlert;
