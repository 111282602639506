import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Pagination {
  constructor(element) {
    this.root = element;
    this.wrapper = element.parentElement;
    this.id = this.root.dataset.id;
    this.mainNav = document.querySelector('body > nav.fixed');

    gsap.defaults({
      ease: 'power2.out',
      duration: 0.25,
    });

    this.SetLinks();
    this.InitLinks();
  }

  SetLinks() {
    this.links = this.root.querySelectorAll('.pagination__link');
  }

  InitLinks() {
    this.links.forEach((link) => {
      this.Event(link);
    });
  }

  Event(link) {
    link.addEventListener('click', async (e) => {
      e.preventDefault();

      const href = link.getAttribute('href');

      // Scroll to the top of the container
      gsap.to(window, {
        duration: 1,
        ease: 'power3.out',
        scrollTo: this.wrapper.offsetTop - this.mainNav.offsetHeight,
      });

      //   await gsap.to(this.root.querySelectorAll(':scope > *'), {
      await gsap.to(this.root, {
        duration: 1,
        ease: 'power3.out',
        opacity: 0,
        onbegin: () => {
          this.wrapper.classList.add('pagination--loading');
        },
      });

      // Fetch the page
      await fetch(href, {
        method: 'get',
      })
        .then((response) => response.text())
        .then((response) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(response, 'text/html');
          // prettier-ignore
          const pagination = doc.querySelector(`.pagination[data-id="${this.id}"]`);

          // Update the inner html
          // If there's a pagination element, we only want to inject that into the page
          if (pagination) {
            this.root.innerHTML = pagination.innerHTML;
          }
          // Otherwise it must be from a ViewableData template so inject the whole thing
          else {
            this.root.innerHTML = response;
          }

          // Reset the links
          this.SetLinks();
          // Re initiate the link events
          this.InitLinks();

          this.wrapper.classList.remove('pagination--loading');
        })
        .catch((err) => {
          console.warn('Something went wrong.', err);
        });

      await gsap.to(this.root, {
        duration: 1,
        ease: 'power3.out',
        opacity: 1,
        onbegin: () => {
          this.wrapper.classList.remove('pagination--loading');
        },
      });
    });
  }
}

export default Pagination;
