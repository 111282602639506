const Header = {
  bindEvents: () => {
    const closeAllSubMenus = () => {
      document.querySelectorAll('.dropdown-submenu__menu').forEach((submenus) => {
        submenus.classList.remove('show');
      });
      document.querySelectorAll('.dropdown-submenu__button').forEach((btn) => {
        btn.setAttribute('aria-expanded', 'false');
      });
    };

    const closeAllMainMenus = () => {
      document.querySelectorAll('.dropdown.show').forEach((menu) => {
        menu.classList.remove('show');
      });
      document.querySelectorAll('.dropdown__button').forEach((btn) => {
        btn.setAttribute('aria-expanded', 'false');
      });
      // close all submenus also
      closeAllSubMenus();
    };

    // Close menus when clicking outside them
    document.addEventListener('click', (ev) => {
      if (!ev.target?.closest('.dropdown')) {
        closeAllMainMenus();
      }
    });

    // Main menu (1st level) items opening a dropdown menu (2nd level)
    document.querySelectorAll('.dropdown').forEach((navItem) => {
      const button = navItem.querySelector('.dropdown__button');
      button?.addEventListener('click', () => {
        const wasShowing = navItem.classList.contains('show');
        // close all menus
        closeAllMainMenus();
        // add show to the correct menu, if it wasn't being closed
        button.setAttribute('aria-expanded', 'true');
        if (!wasShowing) navItem.classList.add('show');
      });
    });

    // Main menu items close buttons
    document.querySelectorAll('.dropdown-menu__close').forEach((button) => {
      button.addEventListener('click', () => {
        // close all menus
        closeAllMainMenus();
      });
    });

    // Submenu (3rd level) opens from dropdown menu items (2nd level)
    document.querySelectorAll('.dropdown-submenu').forEach((menu) => {
      const button = menu.querySelector('.dropdown-submenu__button');
      button?.addEventListener('click', () => {
        // hide all other submenus
        closeAllSubMenus();
        // add show to the correct submenu
        button.setAttribute('aria-expanded', 'true');
        menu.querySelector('.dropdown-submenu__menu')?.classList.add('show');
      });
    });

    // Submenus close buttons (both "close" and the back arrow)
    document.querySelectorAll('.dropdown-submenu__close').forEach((button) => {
      button.addEventListener('click', () => {
        closeAllSubMenus();
      });
    });
  },
};

export default Header;
