import Swiper from 'swiper/bundle';

const ImageCarousel = {
  initCarousel: () => {
    if (document.querySelector('.image-carousel')) {
      new Swiper('.image-carousel', {
        loop: true,
        autoplay: {
          delay: 4500,
        },
        speed: 950,
        spaceBetween: 0,
        slidesPerView: 1,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  },
};

export default ImageCarousel;
