import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Anchors {
  constructor() {
    this.hash = window.location.hash ?? null;
    this.target = this.hash ? document.querySelector(this.hash) : null;
    this.mainNav = document.querySelector('body > nav.fixed');
    this.scrollOffset = 100;

    this.init();
  }

  init() {
    window.onload = () => {
      // Correct the scroll to respect menu height etc
      if (this.hash && this.target) this.scrollTo();
      this.events();
    };
  }

  scrollTo() {
    // If the target is within an accordion, we need to open it first
    const targetAccordion = this.target.closest('.js-accordion__panel');
    const accordionTitle = targetAccordion ? targetAccordion.previousElementSibling : null;
    const accordionTrigger = accordionTitle
      ? accordionTitle.querySelector(':scope > button[aria-expanded="false"]')
      : null;

    if (accordionTrigger) accordionTrigger.click();

    // scroll down to the target offset, less the menu height and an arbitrary offset so it doesn't sit directly under the nav
    gsap.to(window, {
      duration: 0.5,
      ease: 'power1.out',
      scrollTo: this.target.offsetTop - this.mainNav.offsetHeight - this.scrollOffset,
    });
  }

  events() {
    const anchorLinks = document.querySelectorAll('.element a[href*="#"]');

    // Listen to each link on the page that has a hash
    anchorLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        // Set the hash and the target
        const hash = link.href.split('#');
        this.hash = `#${hash[1]}`;
        this.target = document.querySelector(this.hash);

        // If there's a target on this page, scroll to it
        if (this.target) this.scrollTo();
        // otherwise assume it's a link to an anchor on another page
        else window.location.href = link.href;
      });
    });
  }
}

export default Anchors;
