const Tables = {
  init: () => {
    const ids = ['elemental-area', 'programme-page'];

    ids.forEach((id) => {
      if (
        document.getElementById(id) &&
        document.getElementById(id).getElementsByTagName('table')
      ) {
        const tables = document.getElementById(id).getElementsByTagName('table');

        for (const table of tables) {
          const wrapper = document.createElement('div');
          wrapper.classList.add('table-wrapper');
          wrapper.setAttribute('id', 'table-wrapper');
          table.parentNode.insertBefore(wrapper, table);
          wrapper.appendChild(table);
        }
      }
    });
  },
};

export default Tables;
