import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import Pagination from '../Pagination/Pagination';

gsap.registerPlugin(ScrollToPlugin);

const ArchiveSearch = {
  init: () => {
    const form = document.querySelector('form.archive-search');
    if (!form) return;
    const action = form.getAttribute('action');
    const button = form.querySelector('input[type="submit"]');
    const wrapper = document.querySelector('.archive-results');

    form.addEventListener('submit', async (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(form));
      let count = 0;

      button.disabled = true;

      await gsap.to(wrapper, {
        duration: 0.5,
        ease: 'power2.out',
        opacity: 0,
        y: 20,
      });

      // fetch data
      await fetch(`${action}?${formData}&isAjax=1`, {
        method: 'get',
      })
        .then((response) => {
          // Store the article count returned in header
          count = parseInt(response.headers.get('articleCount'), 10);
          return response.text();
        })
        .then((response) => {
          // prettier-ignore
          const offsetTop = (wrapper.offsetTop <= 0) ? wrapper.parentElement.offsetTop : wrapper.offsetTop;
          // prettier-ignore
          const offset = offsetTop - document.querySelector('body > nav.fixed').offsetHeight;
          // If we have results, add the cards
          if (count) {
            wrapper.innerHTML = response;
          }
          // No results means we need to display an error message
          else {
            wrapper.innerHTML =
              '<p id="archive-search-error" class="m-auto">Sorry, there are no articles that match. Please try a different selection.</p>';
          }

          gsap.to(window, {
            duration: 1,
            ease: 'power3.out',
            scrollTo: offset,
          });

          document.querySelectorAll('.pagination').forEach((el) => new Pagination(el));
        })
        .catch((err) => {
          console.warn('Something went wrong.', err);
        });

      await gsap.to(wrapper, {
        duration: 0.5,
        ease: 'power2.out',
        opacity: 1,
        y: 0,
        begin: () => {
          button.disabled = false;
        },
      });
    });
  },
};

export default ArchiveSearch;
