import '@styles/index.scss';

import Header from '@components/Header';
import Links from '@components/Links';
import SiteAlert from '@components/SiteAlert';
import ScrollTo from '@components/ScrollTo';
import Breadcrumbs from '@components/Breadcrumbs';
import Navigation from '@components/Navigation';
import PopoutModal from '@components/PopoutModal';
import Search from '@components/Search';
import ArchiveSearch from '@components/ArchiveSearch';
import MultiSelectTag from '@components/MultiSelect';
import '@imgix/js-core';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Pagination from '@components/Pagination/Pagination';
import Ellipsis from '@components/Ellipsis/Ellipsis';
import Anchors from '@components/Anchors/Anchors';
import TagManager from '@components/TagManager';
import StoryCarousel from '@components/StoryCarousel';
import Media from '@components/Media';
import Accordion from '@components/Accordion';
import Tables from '@components/Tables';
import ImageCarousel from '@components/ImageCarousel';
import Tabs from '@components/Tabs';
import StaffTile from '@components/StaffTile';

document.addEventListener('DOMContentLoaded', () => {
  Header.bindEvents();
  document.getElementById('elemental-area') ? Links.bindEvents() : null;
  SiteAlert.init();
  ScrollTo.init();
  Navigation.init();
  Breadcrumbs.init();
  PopoutModal.init();
  Search.init();
  document.querySelectorAll('select[multiple].multi-select').forEach((el) => {
    new MultiSelectTag(el);
  });
  ArchiveSearch.init();
  document.querySelectorAll('.pagination').forEach((el) => new Pagination(el));
  Ellipsis.init();
  new Anchors();
  TagManager.init();

  StoryCarousel.initCarousel();
  StoryCarousel.mobileCarousel();
  Media.bindEvents();
  Accordion.init();
  Tables.init();
  StaffTile.init();
  ImageCarousel.initCarousel();
  document.querySelector('.tablinks') ? Tabs.init() : null;
});
