const Tabs = {
  init: () => {
    // tab set 1 click event
    document.querySelector('.tablinks')
      ? document.querySelectorAll('.tablinks').forEach((tabs) => {
          Tabs.tabLinks(tabs, '.tablinks', '.tabcontent');
        })
      : null;

    // additional tab set on the same page
    document.querySelector('.tablinks2')
      ? document.querySelectorAll('.tablinks2').forEach((tabs) => {
          Tabs.tabLinks(tabs, '.tablinks2', '.tabcontent2');
        })
      : null;

    // tabs scroll for mobile
    document.getElementById('left-scroll')
      ? document.getElementById('left-scroll').addEventListener('click', () => {
          document.getElementById('scrolling-tabs').scrollBy('-300', 'smooth');
        })
      : null;

    document.getElementById('right-scroll')
      ? document.getElementById('right-scroll').addEventListener('click', () => {
          document.getElementById('scrolling-tabs').scrollBy('300', 'smooth');
        })
      : null;

    const domesticHeading = document.querySelector('[data-tab="Domestic"]');
    const internationalHeading = document.querySelector('[data-tab="International"]');

    const domesticTabContent = document.getElementById('Domestic');
    const internationalTabContent = document.getElementById('International');

    if (!domesticHeading && !domesticTabContent) {
      if (internationalHeading && internationalTabContent) {
        internationalHeading.classList.add('active');
        internationalTabContent.classList.remove('hidden');
      }
    }
  },

  /**
   * @param links
   * @param content
   */
  tabLinks: (tabs, linkClass, contentClass) => {
    tabs.addEventListener('click', (tab) => {
      tab.preventDefault();
      // hide all other tabcontent
      document.querySelectorAll(contentClass).forEach((tabcontent) => {
        tabcontent.classList.add('hidden');
      });

      // hide all other active tab links
      document.querySelectorAll(linkClass).forEach((links) => {
        links.classList.remove('active');
      });

      // add show to the correct tab and active link
      document.getElementById(tabs.dataset.tab).classList.remove('hidden');
      tabs.classList.add('active');
    });
  },
};

export default Tabs;
