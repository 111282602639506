const Ellipsis = {
  init: () => {
    const overflowElements = document.querySelectorAll('.related-posts__tags-categories');
    Ellipsis.overflowElements = overflowElements;

    Ellipsis.checkTagsWidth(overflowElements);
    Ellipsis.addEllipsisEvent();
  },
  checkTagsWidth: (overflowElements) => {
    overflowElements.forEach((el) => {
      const inner = el.querySelector('.related-posts__tags-categories-inner');
      if (el.offsetWidth < inner.offsetWidth && !el.querySelector('.related-posts__ellipsis')) {
        const ellipsis = document.createElement('span');
        ellipsis.classList.add('related-posts__ellipsis');
        ellipsis.innerHTML = '...';
        el.appendChild(ellipsis);
      } else if (el.offsetWidth >= inner.offsetWidth) {
        const ellipsis = el.querySelector('.related-posts__ellipsis');
        if (ellipsis) {
          ellipsis.remove();
        }
      }
    });
  },
  addEllipsisEvent: () => {
    const ellipsises = document.querySelectorAll('.related-posts__ellipsis');
    ellipsises.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const parent = el.parentNode;
        const inner = parent.querySelector('.related-posts__tags-categories-inner');
        parent.classList.remove('overflow-hidden', 'h-8');
        inner.classList.remove('whitespace-nowrap');
        parent.querySelector('.related-posts__ellipsis').remove();
      });
    });
  },
};

window.addEventListener('resize', () => {
  Ellipsis.checkTagsWidth(Ellipsis.overflowElements);
  Ellipsis.addEllipsisEvent();
});

export default Ellipsis;
