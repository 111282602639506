const Media = {
  bindEvents: () => {
    // toggle content
    document.querySelectorAll('.click-toggle').forEach((item) => {
      item.addEventListener('click', () => {
        item.querySelector('.click-content').classList.toggle('hidden');
        item.querySelector('.click-arrow').classList.toggle('toggle-arrow');
      });
    });
  },
};

export default Media;
