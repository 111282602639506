const Links = {
  bindEvents: () => {
    //  add classes to links
    // exclude certain elements
    const links = document.getElementById('elemental-area').getElementsByTagName('a');

    const fileExtArr = [
      '',
      'ace',
      'arc',
      'arj',
      'asf',
      'au',
      'avi',
      'bmp',
      'bz2',
      'cab',
      'cda',
      'csv',
      'dmg',
      'doc',
      'docx',
      'dotx',
      'flv',
      'gif',
      'gpx',
      'gz',
      'hqx',
      'ico',
      'jpeg',
      'jpg',
      'kml',
      'm4a',
      'm4v',
      'mid',
      'midi',
      'mkv',
      'mov',
      'mp3',
      'mp4',
      'mpa',
      'mpeg',
      'mpg',
      'ogg',
      'ogv',
      'pages',
      'pcx',
      'pdf',
      'png',
      'pps',
      'ppt',
      'pptx',
      'potx',
      'ra',
      'ram',
      'rm',
      'rtf',
      'sit',
      'sitx',
      'tar',
      'tgz',
      'tif',
      'tiff',
      'txt',
      'wav',
      'webm',
      'wma',
      'wmv',
      'xls',
      'xlsx',
      'xltx',
      'zip',
      'zipx',
    ];

    for (const link of links) {
      const href = link.getAttribute('href');
      const ext = href ? href.slice(-3) : '';

      if (
        !link.classList.contains('btn') &&
        !link.classList.contains('no-link-style') &&
        !link.classList.contains('link--icon') &&
        !link.classList.contains('link--content') &&
        !!href
      ) {
        if (link.getAttribute('target') === '_blank') {
          link.classList.add('link--external');
        }

        if (fileExtArr.includes(ext)) {
          link.classList.add('link--download');
        }
      }
    }
  },
};

export default Links;
