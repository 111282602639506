const Search = {
  init: () => {
    const form = document.getElementById('global-search-form');
    document.querySelectorAll('.change-field').forEach((fields) => {
      fields.addEventListener('change', () => {
        form.submit();
      });
    });

    // onchange of category clear the select field data
    document.querySelectorAll('.category-field').forEach((fields) => {
      fields.addEventListener('change', () => {
        // clear select fields
        document.querySelectorAll('.category-clear').forEach((field) => {
          field.value = '';
        });

        // submit form
        form.submit();
      });
    });

    // incrementally add pages to div when read more is clicked
    if (document.getElementById('view-more-button')) {
      document.getElementById('view-more-button').addEventListener('click', () => {
        const searchResults = document.getElementById('search-results');
        const pageStart = parseInt(document.getElementById('page-start').value, 10);
        const pageCounter = parseInt(document.getElementById('page-counter').value, 10);
        // add page results count to page start when adding the next lot of results in
        const start = pageStart + pageCounter;
        const path = window.location.href.split('?')[0];

        // add page start to results for next results to be added
        document.getElementById('page-start').value = start;

        // fetch data
        fetch(`${path}home/loadMoreResponse${window.location.search}&start=${start}`)
          .then((response) => response.text())
          .then((html) => {
            if (html.length === 1) {
              document.getElementById('view-more-button').classList.add('hidden');
            }
            searchResults.innerHTML += html;
          })
          .catch((err) => {
            console.warn('Something went wrong.', err);
          });
      });
    }

    // search modal
    if (
      document.getElementById('search-toggle--desktop') ||
      document.getElementById('search-toggle--mobile')
    ) {
      const toggleSearchBtns = document.querySelectorAll('.search-toggle');
      const searchModal = document.getElementById('search-modal');
      const overlayModal = document.getElementById('modal-overlay');
      const searchInput = searchModal.getElementsByTagName('INPUT');

      document.body.addEventListener('click', (e) => {
        if (e.target.id === 'modal-overlay') {
          overlayModal.classList.toggle('hidden');
          searchModal.classList.toggle('search-modal-open');
          searchModal.setAttribute(
            'aria-hidden',
            searchModal.getAttribute('aria-hidden') === 'true' ? 'false' : 'true'
          );
          toggleSearchBtns.forEach((btn) => {
            btn.classList.toggle('search-closed');
          });
        }
      });

      toggleSearchBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
          btn.classList.toggle('search-closed');
          overlayModal.classList.toggle('hidden');
          searchModal.classList.toggle('search-modal-open');
          searchModal.setAttribute(
            'aria-hidden',
            searchModal.getAttribute('aria-hidden') === 'true' ? 'false' : 'true'
          );
          if (searchModal.classList.contains('search-modal-open')) {
            if (searchInput) {
              searchInput[1].focus();
            }
          }
        });
      });
    }
  },
};

export default Search;
