/**
 * Adds some custom DataLayer events
 * Note - the GTM script should be injected via the CMS Settings > Code > Head & Body fields
 */
const TagManager = {
  // Extracted just for debugging purposes
  push: (data) => {
    // console.log(JSON.stringify(data));
    window.dataLayer.push(data);
  },

  init: () => {
    window.dataLayer = window.dataLayer || [];
    const urlParams = window.location.search;

    // Contact/Enquiry form submissions
    if (urlParams.match(/[?&]cs=1/i)) {
      TagManager.push({ event: 'generate_lead' });
    }

    // Search form submissions
    const keywords = urlParams.match(/[?&]Keywords=([^?&]+)/i);
    if (urlParams.match(/[?]Search=1/) && keywords) {
      const category = urlParams.match(/[?&]category=([^?&]+)/i);
      const from = urlParams.includes('from=header') ? 'header' : 'in_page';
      TagManager.push({
        event: 'search',
        search_from: from,
        search_term: decodeURIComponent(keywords[1]),
        search_filter: category || '',
      });
    }

    // Collect up some info for the global nav apply now button
    const items = [];
    let listName = null;

    // Programme Areas List (view, click)
    const programmeLists = document.querySelectorAll('.gtm-programme-list');
    Array.from(programmeLists).forEach((el) => {
      listName = (el.querySelector('.gtm-list-name')?.textContent || '').trim();
      Array.from(el.querySelectorAll('.gtm-programme-item')).forEach((item) => {
        const category = (item.querySelector('.gtm-item-category')?.textContent || '').trim();
        const title = (item.querySelector('.gtm-item-title')?.textContent || '').trim();
        // Collect up the list items to send view_item_list
        items.push({
          item_name: title,
          item_category: category,
        });

        // Also listen to link clicks to send select_item or apply_now
        [
          ['a.gtm-select-item', 'select_item'],
          ['a.gtm-apply-now', 'apply_now'],
        ].forEach((tuple) => {
          Array.from(item.querySelectorAll(tuple[0])).forEach((link) => {
            link.addEventListener('click', (e) => {
              // Stop link action and defer until dataLayer.push has finished (using GA4 callback)
              e.preventDefault();
              TagManager.pushAndRedirect(tuple[1], link.href, {
                item_list_name: listName,
                items: [
                  {
                    item_name: title,
                    item_category: category,
                  },
                ],
              });
            });
          });
        });
      });

      TagManager.push({ ecommerce: null });
      TagManager.push({
        event: 'view_item_list',
        ecommerce: {
          item_list_name: listName,
          items,
        },
      });
    });

    // Collect up some info for the global nav apply now button
    let title = null;
    let category = null;
    let variant = null;
    let level = '';

    // Programme pages (view, change domestic/intl tab, apply now)
    Array.from(document.querySelectorAll('.gtm-programme-page')).forEach((page) => {
      title = (page.querySelector('h1')?.textContent || '').trim();
      category = page.getAttribute('data-category') || '';
      const activeTab = page.querySelector(
        '[data-tab="Domestic"].active,[data-tab="International"].active'
      );
      variant = activeTab?.getAttribute('data-tab') || '';
      level = activeTab?.getAttribute('data-level') || '';
      TagManager.pushViewItem(title, category, variant, level);

      Array.from(
        document.querySelectorAll('[data-tab="Domestic"],[data-tab="International"]')
      ).forEach((tab) => {
        tab.addEventListener('click', (e) => {
          variant = tab.getAttribute('data-tab');
          level = tab.getAttribute('data-level') || '';
          TagManager.pushViewItem(title, category, variant, level);
        });
      });

      const applyNows = Array.from(page.querySelectorAll('.gtm-apply-now'));
      applyNows.forEach((link) => {
        link.addEventListener('click', (e) => {
          // Stop link action and defer until dataLayer.push has finished (using GA4 callback)
          e.preventDefault();
          TagManager.pushAndRedirect('apply_now', link.href, {
            items: [
              {
                item_name: title,
                item_category: category,
                item_variant: variant,
              },
            ],
          });
        });
      });
    });

    // Short course pages (view & apply now)
    Array.from(document.querySelectorAll('.gtm-short-course')).forEach((page) => {
      title = (page.querySelector('h2')?.textContent || '').trim();
      category = page.getAttribute('data-category') || '';
      TagManager.pushViewItem(title, category);

      const applyNows = Array.from(page.querySelectorAll('.gtm-apply-now'));
      applyNows.forEach((link) => {
        link.addEventListener('click', (e) => {
          // Stop link action and defer until dataLayer.push has finished (using GA4 callback)
          e.preventDefault();
          TagManager.pushAndRedirect('apply_now', link.href, {
            items: [
              {
                item_name: title,
                item_category: category,
              },
            ],
          });
        });
      });
    });

    const applyNowBtn = document.querySelector('nav .gtm-apply-now');
    applyNowBtn?.addEventListener('click', (e) => {
      // Stop link action and defer until dataLayer.push has finished (using GA4 callback)
      e.preventDefault();
      if (title && category) {
        const ecommerce = {
          items: [
            {
              item_name: title,
              item_category: category,
            },
          ],
        };
        if (variant) ecommerce.item_variant = variant;
        TagManager.pushAndRedirect('apply_now', applyNowBtn.href, ecommerce);
      } else if (listName && items.length) {
        const ecommerce = {
          item_list_name: listName,
          items,
        };
        TagManager.pushAndRedirect('apply_now', applyNowBtn.href, ecommerce);
      } else {
        TagManager.pushAndRedirect('apply_now', applyNowBtn.href);
      }
    });
  },

  pushAndRedirect: (event, href, ecommerce = null) => {
    const data = {
      event,
      eventCallback: (id) => {
        // Stop opening URLs twice on UAT (seems to push both G- and GTM- events), but on Prod this id is undefined
        if (typeof id !== 'string' || !id.startsWith('G-')) {
          document.location = href;
        }
      },
    };
    if (ecommerce) {
      data.ecommerce = ecommerce;
      TagManager.push({ ecommerce: null });
    }
    TagManager.push(data);
  },

  pushViewItem: (name, category, variant = null, level = null) => {
    TagManager.push({ ecommerce: null });
    const item = {
      item_name: name,
      item_category: category,
    };
    if (variant) item.item_variant = variant;
    if (typeof level === 'string') item.item_level = level;
    TagManager.push({
      event: 'view_item',
      ecommerce: {
        items: [item],
      },
    });
  },
};

export default TagManager;
